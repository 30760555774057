import { createAsyncThunk } from '@reduxjs/toolkit'
import {fetchAPI} from "../../../services/fetchAPI";

export const getTemplateById = createAsyncThunk(
    'template/getTemplateById',
    async (payload) => {
        return await fetchAPI(`/api/template/byId`, {id: payload})
    }
)

export const getTemplates = createAsyncThunk(
    'template/getTemplates',
    async () => {
        return await fetchAPI(`/api/template/list`)
    }
)

export const updateTemplate = createAsyncThunk(
    'template/updateTemplate',
    async (payload) => {
        return await fetchAPI(`/api/template/update`, payload)
    }
)

export const addTemplate = createAsyncThunk(
    'template/addTemplate',
    async (payload) => {
        return await fetchAPI(`/api/template/add`, payload)
    }
)