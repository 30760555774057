import {createSlice} from '@reduxjs/toolkit'
import {addTemplate, getTemplates} from './thunks'

const templateInitialState = {
    _id: "",
    name: "",
    subject: "",
    subTitle: "",
    screenShot: "",
    contentJson: "",
    contentMjml: "",
    contentHtml: "",
    mergeTags: "",
    product: "",
    companyId: "",
    active: false,
    createdOn: "",
    createdBy: "",
    updatedOn: "",
    updatedBy: "",
    list: null,
    isLoading: false

}
const templateSlice = createSlice({
    name: 'template',
    initialState: templateInitialState,
    reducers: {},
    extraReducers: {
        [getTemplates.pending]: (state) => {
            state.isLoading = true
        },
        [getTemplates.fulfilled]: (state, {payload}) => {
            state.list = payload
            state.isLoading = false
        },
        [addTemplate.pending]: (state) => {
            state.isLoading = true
        },
        [addTemplate.fulfilled]: (state) => {
            state.isLoading = false
        }
    },
})

export default templateSlice.reducer