import React, { Suspense } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { Provider } from 'react-redux';
import { store } from './store';
import './index.css';
import Loader from './assets/loader.gif'
import Home from "./pages/Home";
const Editor = React.lazy(() => import('./pages/Editor'));

const App = () => {
    const history = createBrowserHistory();
return(
    <Provider store={store}>
        <Suspense
            fallback={
                <div
                    style={{
                        width: '100vw',
                        height: '100vh',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <img
                        width="100%"
                        src={Loader}
                        alt=''
                    />
                </div>
            }
        >
            <Router history={history}>
                <Switch>
                    <Route path='/' exact component={Home} />
                    <Route path='/editor' component={Editor} />
                </Switch>
            </Router>
        </Suspense>
    </Provider>
)
}

export default App;
